<template>
  <div>
    <TitleWrapper :filter-option="false" title="Notifications" :display-breadcrumb="true" />
    <div
      :class="$style.minHieght80"
      class="main-content bg-primary-white rounded-t-lg mt-5 p-5 border-border-color-lighter border"
    >
      <!-- New Guardian Enrollment Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for new guardian registration
        </p>
      </div>
      <div
        v-if="!isLoadingNewGuardianEnrollment"
        class="mt-5 rounded-lg border border-primary-grey"
      >
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType v-else :notification-list="newGuardianEnrollmentList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingNewGuardianEnrollment"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(newGuardianEnrollmentCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- De-activate user Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for guardian deactivation
        </p>
      </div>
      <div v-if="!isLoadingDeactivateUser" class="mt-5 rounded-lg border border-primary-grey">
        <div v-if="false">
          <NoRecordFound />
        </div>

        <NotificationTableType v-else :notification-list="deActivateUserList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingDeactivateUser"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(deActivateUserCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />

      <!-- New Staff Creation Notification -->
      <div class="flex justify-between">
        <p class="text-xl flex w-full md:mt-4 pr-3 mb-3 font-bold">
          Reminder for guardian login enable/diable
        </p>
      </div>
      <div
        v-if="!isLoadingGuardianEnableDisable"
        class="mt-5 rounded-lg border border-primary-grey"
      >
        <div v-if="false">
          <NoRecordFound />
        </div>
        <NotificationTableType v-else :notification-list="guardianEnableDisableList" />
      </div>
      <div v-else class="mt-16 mb-28">
        <Loader :content="true" />
      </div>
      <Pagination
        v-if="true"
        v-show="!isLoadingGuardianEnableDisable"
        :record-limit="filteredRecordLimit"
        :max-range="paginationCounts(guardianEnableDisableCount, filteredRecordLimit)"
        @filterRecord="filterRecord"
      />
    </div>
    <AddExamTemplateType
      v-if="showNewGuardianEnrollmentModal"
      :show-model="showNewGuardianEnrollmentModal"
      label="new guardian enrollment"
      @handleClick="toggleShowNewGuardianEnrollmentModal"
      @update="addNewGuardianEnrollment"
    />
    <AddExamTemplateType
      v-if="showDeActivateUserModal"
      label="de-activate guardian"
      :show-model="showDeActivateUserModal"
      @handleClick="toogleShowDeActivateUserModal"
      @update="addDeActivateUser"
    />
    <AddExamTemplateType
      v-if="showGuardianEnableDisableModal"
      label="guardian enable/disable"
      :show-model="showGuardianEnableDisableModal"
      @handleClick="toggleShowGuardianEnableDisableModal"
      @update="assGuardianEnableDisable"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import AddExamTemplateType from '@/src/components/Settings/notifications/CreateUnScheduleNotification.vue'
import NotificationTableType from '@/src/components/Settings/notifications/NotificationTableType2.vue'
/* Utils or mixins */
import GeneralUtil from '@src/mixins/general-mixins.js'
import { mapActions } from 'vuex'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'

export default {
  components: {
    TitleWrapper,
    Pagination,
    Loader,
    AddExamTemplateType,
    NotificationTableType,
    NoRecordFound,
  },

  mixins: [GeneralUtil],
  page: {
    title: 'Notification | Staff',
  },
  data() {
    return {
      notifications: 'notifications',
      isLoadingNewGuardianEnrollment: false,
      isLoadingDeactivateUser: false,
      isLoadingGuardianEnableDisable: false,
      newGuardianEnrollmentCount: 20,
      deActivateUserCount: 20,
      guardianEnableDisableCount: 20,
      filteredRecordLimit: 5,
      showNewGuardianEnrollmentModal: false,
      showDeActivateUserModal: false,
      showGuardianEnableDisableModal: false,
      newGuardianEnrollmentList: [
        {
          role: {
            id: '0',
            name: 'Guardian',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Guardian',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          days: '2',
          days_before: {
            id: '0',
            name: 'Before',
          },
          due_date: '2:00 pm',
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      deActivateUserList: [
        {
          role: {
            id: '0',
            name: 'Guardian',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },

        {
          role: {
            id: '0',
            name: 'Guardian',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
      guardianEnableDisableList: [
        {
          role: {
            id: '0',
            name: 'Guardian',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
        {
          role: {
            id: '0',
            name: 'Guardian',
          },
          user: {
            id: '0',
            name: 'Awais',
          },
          mediums: [
            { id: '1', name: 'Email' },
            { id: '3', name: 'In App Notification' },
            { id: '2', name: 'Sms' },
          ],
        },
      ],
    }
  },
  created() {
    this.setRightBar()
  },
  methods: {
    setRightBar() {
      const rightBarContent = {
        header: {
          title: 'Notifications',
          buttons: [
            {
              title: 'Create New Notification',
              classes: ['primary-button-right'],
              options: [
                {
                  title: 'NEW_GUARDIAN_ENROLLMENT',
                  action: {
                    func: () => {
                      this.toggleShowNewGuardianEnrollmentModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'GUARDIAN_ACTIVATE',
                  action: {
                    func: () => {
                      this.toogleShowDeActivateUserModal()
                    },
                  },
                  payload: {},
                },
                {
                  title: 'GUARDIAN_ENABLE_DISABLE',
                  action: {
                    func: () => {
                      this.toggleShowGuardianEnableDisableModal()
                    },
                  },
                  payload: {},
                },
              ],
            },
          ],
        },
      }
      this.setRightbarContent(rightBarContent)
    },

    addNewGuardianEnrollment(e) {
      this.newGuardianEnrollmentList.push(e)
    },

    addDeActivateUser(e) {
      this.deActivateUserList.push(e)
    },

    assGuardianEnableDisable(e) {
      this.guardianEnableDisableList.push(e)
    },

    filterRecord() {},

    toggleShowNewGuardianEnrollmentModal(eventName) {
      this.showNewGuardianEnrollmentModal = !this.showNewGuardianEnrollmentModal
    },

    toogleShowDeActivateUserModal(eventName) {
      this.showDeActivateUserModal = !this.showDeActivateUserModal
    },

    toggleShowGuardianEnableDisableModal(eventName) {
      this.showGuardianEnableDisableModal = !this.showGuardianEnableDisableModal
    },
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" module>
.minHieght80 {
  min-height: 80vh;
}
</style>
